<template>
  <form @submit.prevent="submit">
    <p>
      <label>
        Voornaam <br/>
        <input type="text" v-model="firstName" required />
      </label>
    </p>

    <p>
      <label>
        Achternaam <br/>
        <input type="text" v-model="lastName" required />
      </label>
    </p>

    <p>
      <label>
        Geslacht <br/>
        <select v-model="gender" required>
          <option value="">Man / vrouw</option>
          <option value="Man">Man</option>
          <option value="Vrouw">Vrouw</option>
        </select>
      </label>
    </p>

    <p>
      <label>
        Geboortedatum <br/>
        <input
          type="date"
          data-format="yyyy-mm-dd"
          :value="birthdate"
          @change="birthdate = $event.target.value" />
      </label>
    </p>

    <h3>Contactgegevens</h3>

    <p>
      <label>
        E-mailadres <br/>
        <input type="email" v-model="email" />
      </label>
    </p>

    <p>
      <label>
        Telefoonnummer <br/>
        <input type="text" v-model="phonenumber" />
      </label>
    </p>

    <p>
      <label>
        Mobiel nummer <br/>
        <input type="text" v-model="mobilePhonenumber" />
      </label>
    </p>

    <h3>Adresgegevens</h3>

    <p>
      <label>
        Adres <br/>
        <input type="text" v-model="streetWithHouseNumber" required />
      </label>
    </p>

    <p>
      <label>
        Postcode <br/>
        <input type="text" v-model="zipcode" required />
      </label>
    </p>

    <p>
      <label>
        Woonplaats <br/>
        <input type="text" v-model="town" required />
      </label>
    </p>

    <p>
      <label>
        Provincie <br/>
        <input type="text" v-model="province" required />
      </label>
    </p>

    <h3>Financiën</h3>

    <p>
      <label>
        IBAN <br/>
        <input type="text" v-model="iban" />
      </label>
    </p>

    <p>
      <label>
        BIC <br/>
        <input type="text" v-model="bic" />
      </label>
    </p>

    <template v-if="groupResult">
      <h3>Lidmaatschappen</h3>

      <MembershipInput
        v-for="membership in memberships"
        :membership="membership"
        :groups="groupResult.groups"
        :key="membership.id"
        @set-group="$value => { membership.groupId = $value }"
        @set-role="$value => { membership.role = $value }"
        @remove="memberships.splice(memberships.indexOf(membership), 1)" />

      <p><a class="add-membership" @click="memberships.push({})">Lidmaatschap toevoegen</a></p>
    </template>

    <template v-if="id">
      <h3>Status lidmaatschap</h3>
      <p v-if="id">
        <label>
          Uitschrijfdatum <br/>
          <input
            type="date"
            data-format="yyyy-mm-dd"
            :value="unregisterDate"
            @change="unregisterDate = $event.target.value" />
        </label>
      </p>
    </template>

    <p v-if="!isValid">
      Een lid moet minstens in 1 groep zitten.
    </p>

    <p v-else>
      <button>{{ buttonValue }}</button>
    </p>
  </form>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  reactive,
  toRefs,
} from 'vue';
import { useQuery } from 'villus';
import MembershipInput from './MembershipInput.vue';

export default defineComponent({
  components: { MembershipInput },

  props: {
    user: Object as PropType<User>,
    buttonValue: String,
  },

  setup(props, { emit }) {
    const newUser = {
      firstName: '',
      lastName: '',
      birthdate: '',
      gender: '',
      email: '',
      phonenumber: '',
      mobilePhonenumber: '',
      streetWithHouseNumber: '',
      zipcode: '',
      town: '',
      province: '',
      iban: '',
      bic: '',
      memberships: [],
    };

    const Group = `
      query {
        groups {
          id
          name
        }
      }
    `;

    const { data: groupResult } = useQuery(Group);

    const variables = reactive(props.user ?? newUser);
    const isValid = computed(() => variables.memberships.length > 0);

    const submit = () => emit('save-user', variables);

    return {
      ...toRefs(variables),
      submit,
      groupResult,
      isValid,
    };
  },
});
</script>

<style lang="scss">
.add-membership {
  cursor: pointer;
  text-decoration: underline;
}
</style>
