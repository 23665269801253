<template>
  <p>
    <select @change="updateGroup">
      <option
        v-for="group in groups"
        :key="group.id"
        :value="group.id"
        :selected="group.id == membership.groupId">
        {{ group.name }}
      </option>
    </select>

    <select @change="updateRole">
      <option
        v-for="role in roles"
        :key="role"
        :value="role"
        :selected="role == membership.role">
        {{ role }}
      </option>
    </select>

    <a class="remove" @click="remove(membership)">Wis</a>
  </p>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    groups: Array,
    membership: Object as PropType<Membership>,
  },

  setup(props, { emit }) {
    const roles = ['Algemeen lid', 'Aspirant lid', 'Jeugdlid', 'Bestuurslid', 'Contactpersoon'];

    const remove = (membership: Membership) => emit('remove', membership);
    const updateGroup = (evt: UserEvent) => emit('set-group', evt.target.value);
    const updateRole = (evt: UserEvent) => emit('set-role', evt.target.value);

    if (typeof props.groups !== 'undefined' && (props.membership as Membership).groupId == null) {
      emit('set-group', (props.groups[0] as Group).id);
    }

    if ((props.membership as Membership).role == null) {
      emit('set-role', roles[0]);
    }

    return {
      remove,
      roles,
      updateGroup,
      updateRole,
    };
  },
});
</script>

<style lang="scss" scoped>
select {
  margin-right: 10px;
  min-width: auto;
  width: 145px;
}

.remove {
  cursor: pointer;
  text-decoration: underline;
}
</style>
