<template>
  <div>
    <h1>Nieuw lid toevoegen</h1>
    <UserForm buttonValue="Toevoegen" @save-user="saveUser" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useMutation } from 'villus';
import { useRouter } from 'vue-router';

import UserForm from '../components/UserForm.vue';

export default defineComponent({
  components: { UserForm },

  setup() {
    const router = useRouter();

    const CreateUser = `
      mutation CreateUser($firstName: String,
                          $lastName: String!,
                          $email: String,
                          $phonenumber: String,
                          $mobilePhonenumber: String,
                          $streetWithHouseNumber: String!,
                          $zipcode: String!,
                          $town: String!,
                          $gender: String!,
                          $province: String!,
                          $iban: String,
                          $bic: String,
                          $birthdate: String,
                          $memberships: [MembershipInput]) {
        CreateUser(firstName: $firstName,
                   lastName: $lastName,
                   email: $email,
                   phonenumber: $phonenumber,
                   mobilePhonenumber: $mobilePhonenumber,
                   streetWithHouseNumber: $streetWithHouseNumber,
                   zipcode: $zipcode,
                   gender: $gender,
                   town: $town,
                   province: $province,
                   iban: $iban,
                   bic: $bic,
                   birthdate: $birthdate,
                   memberships: $memberships) {
          id
        }
      }
    `;

    const { execute } = useMutation(CreateUser);

    const saveUser = (user: User) => {
      execute(user)
        .then((result) => {
          router.replace({ name: 'UserDetails', params: { id: result.data.CreateUser.id } });
        });
    };

    return { saveUser };
  },
});
</script>
