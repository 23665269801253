
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    groups: Array,
    membership: Object as PropType<Membership>,
  },

  setup(props, { emit }) {
    const roles = ['Algemeen lid', 'Aspirant lid', 'Jeugdlid', 'Bestuurslid', 'Contactpersoon'];

    const remove = (membership: Membership) => emit('remove', membership);
    const updateGroup = (evt: UserEvent) => emit('set-group', evt.target.value);
    const updateRole = (evt: UserEvent) => emit('set-role', evt.target.value);

    if (typeof props.groups !== 'undefined' && (props.membership as Membership).groupId == null) {
      emit('set-group', (props.groups[0] as Group).id);
    }

    if ((props.membership as Membership).role == null) {
      emit('set-role', roles[0]);
    }

    return {
      remove,
      roles,
      updateGroup,
      updateRole,
    };
  },
});
