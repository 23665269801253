
import {
  PropType,
  computed,
  defineComponent,
  reactive,
  toRefs,
} from 'vue';
import { useQuery } from 'villus';
import MembershipInput from './MembershipInput.vue';

export default defineComponent({
  components: { MembershipInput },

  props: {
    user: Object as PropType<User>,
    buttonValue: String,
  },

  setup(props, { emit }) {
    const newUser = {
      firstName: '',
      lastName: '',
      birthdate: '',
      gender: '',
      email: '',
      phonenumber: '',
      mobilePhonenumber: '',
      streetWithHouseNumber: '',
      zipcode: '',
      town: '',
      province: '',
      iban: '',
      bic: '',
      memberships: [],
    };

    const Group = `
      query {
        groups {
          id
          name
        }
      }
    `;

    const { data: groupResult } = useQuery(Group);

    const variables = reactive(props.user ?? newUser);
    const isValid = computed(() => variables.memberships.length > 0);

    const submit = () => emit('save-user', variables);

    return {
      ...toRefs(variables),
      submit,
      groupResult,
      isValid,
    };
  },
});
